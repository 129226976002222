var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-container"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_c('div',{ref:"grid",staticClass:"grid"},[_vm._l((_vm.rimCells),function(cell,index){return _c('cell',{key:cell._id + '-' + index,staticClass:"outer-cell",style:({
        width: _vm.rimCellWidth + 'px',
        height: _vm.rimCellHeight + 'px',
        position: 'absolute',
        top: cell.position.y * _vm.rimCellHeight + 'px',
        left: cell.position.x * _vm.rimCellWidth + 'px',
        'z-index': _vm.isCellCorner(cell)
          ? '2'
          : _vm.isPrevCellCorner(cell)
          ? '1'
          : '5',
      }),attrs:{"cell":Object.assign({}, cell)},on:{"cell-clicked":function($event){return _vm.outerCellClicked(cell, index)}}})}),(_vm.centerCell)?_c('cell',{key:_vm.centerCell._id + '-main-cell',staticClass:"main-cell",style:({
        width: 3 * _vm.rimCellWidth - 24 + 'px',
        height: 3 * _vm.rimCellHeight - 24 + 'px',
        position: 'absolute',
        top: _vm.rimCellHeight + 12 + 'px',
        left: _vm.rimCellWidth + 12 + 'px',
      }),attrs:{"cell":Object.assign({}, _vm.centerCell, {clickToClickThroughCount: _vm.clickToClickThroughCount, isMainCell: true})},on:{"cell-clicked":function($event){return _vm.mainCellClick(_vm.centerCell)}}}):_vm._e()],2),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-bottom-right-text"},[_c('p',[_vm._v("patent pending")])])}]

export { render, staticRenderFns }